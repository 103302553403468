<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-3 mb-3 border border-secondary">
        <div class="col-sm-3">
          <div class="row">
            <div class="col-sm-3 pr-0 text-right">
              <label class="col-form-label">Paciente:</label>
            </div>
            <div class="col-sm-9 pl-1 pr-2">
              <multiselect
                id="comboPacientes"
                v-model="pacienteSelected"
                :options="pacientes"
                placeholder=""
                label="full_name"
                track-by="full_name"
                select-label=""
                deselect-label="X"
              >
                <span slot="caret" v-if="!(pacienteSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="row">
            <div class="col-sm-4 px-0 text-right">
              <label class="col-form-label">Parentesco:</label>
            </div>
            <div class="col-sm-8 pl-1">
              <b-form-select
                id="comboParentesco"
                v-model="parentescoSelected"
                :options="getParentescos"
                @input="selectBeneficiarioComboTipo"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="row" v-if="tipoSeguroisConjunto && isParentescoSpousesOrChild">
            <div class="col-sm-3 px-0 text-right">
              <label class="col-form-label">Seguro:</label>
            </div>
            <div class="col-sm-9 pl-1">
              <multiselect
                id="comboSeguro"
                v-model="aseguradoraSelected"
                :options="getAseguradoras"
                track-by="id"
                label="nombre"
                placeholder=""
                select-label=""
                deselect-label="X"
              >
                <span slot="caret" v-if="!(aseguradoraSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="row" v-if="tipoSeguroisConjunto && isParentescoSpousesOrChild">
            <div class="col-sm-5 px-0 text-right">
              <label class="col-form-label">Cod. Seguro:</label>
            </div>
            <div class="col-sm-7 px-1">
              <input
                v-model="codigoSeguro"
                type="text"
                class="form-control"
              >
            </div>
          </div>
        </div>
        <div class="col-sm-2 px-1">
          <multiselect
            v-if="tipoSeguroisConjunto && isParentescoSpousesOrChild"
            id="comboTipo"
            v-model="tipoFamiliarSelected"
            :options="tipoFamiliaresList"
            :allow-empty="false"
            :disabled="tipoFamiliarSelected!==null"
            track-by="id"
            label="nombre"
            placeholder=""
            select-label=""
            deselect-label=""
          >
            <span slot="caret" v-if="!(tipoFamiliarSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
          </multiselect>
        </div>
        <div class="col-sm-1 px-2">
          <button type="submit" class="btn  btn-block btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <b-table
      class="table table-hover"
      :fields="autoFields"
      :items="getlistaFamiliares"
    ></b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters, mapMutations } from 'vuex';
import { util } from '../../../plugins/util';

export default {
  name: 'Familiar',
  components: {
    Multiselect,
  },
  data() {
    return {
      pacienteSelected: null,
      parentescoSelected: 1,
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '5%' } },
        {
          key: 'numero_exp', label: 'N° Exp', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'nombre', label: 'Nombre', class: 'text-center', thStyle: { width: '20%' },
        },
        {
          key: 'parentesco', label: 'Parentesco', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'celular', label: 'Celular', class: 'text-center', thStyle: { width: '8%' },
        },
        {
          key: 'edad', label: 'Edad', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'email', label: 'Email', class: 'text-center', thStyle: { width: '17%' },
        },
        {
          key: 'seguro_name', label: 'Aseguradora', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'cod_seguro', label: 'Cod. Seguro', class: 'text-center', thStyle: { width: '10%' },
        },
        {
          key: 'tipo_familiar_nombre', label: 'Tipo', class: 'text-center', thStyle: { width: '9%' },
        },
      ],
      aseguradoraSelected: null,
      codigoSeguro: '',
      tipoFamiliarSelected: null,
    };
  },
  beforeDestroy() {
    this.setlistaFamiliares([]);
  },
  methods: {
    resetearInputPaciente() {
      this.pacienteSelected = null;
    },
    resetearComboParentesco() {
      this.parentescoSelected = 1;
    },
    resetearComboAseguradora() {
      this.aseguradoraSelected = null;
    },
    resetearInputCodigoSeguro() {
      this.codigoSeguro = '';
    },
    resetearComboTipoFamiliar() {
      this.tipoFamiliarSelected = null;
    },
    agregarItem() {
      if (this.pacienteSelected === null) {
        util.showNotify('Elige un Paciente ', 'warn');
        return;
      }
      let seguroId = null;
      let seguroName = null;
      let tipoFamiliarId = null;
      let tipoFamiliarNombre = null;
      let codigoSeguro = '';
      if (this.tipoSeguroisConjunto && this.isParentescoSpousesOrChild) {
        const TITULAR = 1;
        const BENEFICIARIO = 2;
        const item = this.getlistaSeguros.find((el) => el.tipo_familiar_id === TITULAR);
        const LISTA_BENEFICIARIOS = this.getlistaFamiliares.filter((el) => (
          el.tipo_familiar_id === BENEFICIARIO
        ));
        if (LISTA_BENEFICIARIOS.length >= item.cantidad) {
          util.showNotify('Ya se alcanzó el máximo de beneficiarios.', 'warn');
          return;
        }
        if (this.aseguradoraSelected === null) {
          util.showNotify('Elige una Aseguradora', 'warn');
          return;
        }
        if (this.codigoSeguro === '') {
          util.showNotify('Escribe el Código del Seguro.', 'warn');
          return;
        }
        if (this.tipoFamiliarSelected === null) {
          util.showNotify('Elige un tipo de Familiar', 'warn');
          return;
        }
        seguroId = this.aseguradoraSelected.id;
        seguroName = this.aseguradoraSelected.nombre;
        codigoSeguro = this.codigoSeguro;
        tipoFamiliarId = this.tipoFamiliarSelected.id;
        tipoFamiliarNombre = this.tipoFamiliarSelected.nombre;
      }
      const numero = this.getlistaFamiliares.length + 1;
      const item = {
        numero,
        id: 0,
        numero_exp: this.pacienteSelected.numero,
        nombre: this.pacienteSelected.full_name,
        parentesco: this.getParentescos[this.parentescoSelected - 1].text,
        celular: this.pacienteSelected.celular,
        edad: this.pacienteSelected.edad,
        email: this.pacienteSelected.email,
        paciente_familiar_id: this.pacienteSelected.id,
        parentesco_id: this.parentescoSelected,
        seguro_id: seguroId,
        seguro_name: seguroName,
        cod_seguro: codigoSeguro,
        tipo_familiar_id: tipoFamiliarId,
        tipo_familiar_nombre: tipoFamiliarNombre,
        estado: 'true',
      };
      this.addItemlistaFamiliares(item);
      this.resetearInputPaciente();
      this.resetearComboParentesco();
      this.resetearComboAseguradora();
      this.resetearInputCodigoSeguro();
      this.resetearComboTipoFamiliar();
    },
    selectBeneficiarioComboTipo() {
      if (this.tipoSeguroisConjunto === false) {
        this.tipoFamiliarSelected = null;
        return;
      }
      if (this.parentescoSelected === 3 || this.parentescoSelected === 10) {
        const ITEM_BENEFICIARIO = this.getTiposFamiliares[1];
        this.tipoFamiliarSelected = ITEM_BENEFICIARIO;
      } else {
        this.tipoFamiliarSelected = null;
      }
    },
    ...mapMutations('familiar', ['setlistaFamiliares', 'addItemlistaFamiliares']),
  },
  computed: {
    pacientes() {
      return this.getPacientes.filter((paciente) => (
        !this.getlistaFamiliares.some((item) => item.paciente_familiar_id === paciente.id)
      ));
    },
    tipoFamiliaresList() {
      return [this.getTiposFamiliares[1]];
    },
    autoFields() {
      if (this.tipoSeguroisConjunto) {
        return this.fields;
      }
      return this.fields.filter((obj) => (
        obj.key !== 'seguro_name'
        && obj.key !== 'cod_seguro'
        && obj.key !== 'tipo_familiar_nombre'
      ));
    },
    isParentescoSpousesOrChild() {
      return this.parentescoSelected === 3 || this.parentescoSelected === 10;
    },
    ...mapGetters('mainForm', [
      'tipoSeguroisConjunto',
    ]),
    ...mapGetters('aseguradoras', ['getAseguradoras']),
    ...mapGetters('familiar', ['getPacientes', 'getlistaFamiliares']),
    ...mapGetters('tipoFamiliares', ['getTiposFamiliares']),
    ...mapGetters('parentescos', ['getParentescos']),
    ...mapGetters('seguroMedico', ['getlistaSeguros']),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
