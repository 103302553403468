<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-3 mb-3 border border-secondary">
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-2 pr-0 text-right">
              <label class="col-form-label">Medico:</label>
            </div>
            <div class="col-sm-10">
              <multiselect v-model="medicoSelected" :options="medicos"
                placeholder="" label="nombre" track-by="nombre"
                select-label="" deselect-label="X"
                @input="actualizarInputEspecialidad">
                <span slot="caret" v-if="!(medicoSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="row">
            <div class="col-sm-3 pr-0 text-right">
              <label class="col-form-label">Especialidad:</label>
            </div>
            <div class="col-sm-6">
              <input type="text" class="form-control" autocomplete="off"
              v-model="especialidadSelected" disabled>
            </div>
          </div>
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-2 text-center">
          <button type="submit" class="btn btn-warning" :disabled="especialidadSelected===null">
            Agregar
          </button>
        </div>
      </div>
    </form>
    <b-table class="table table-hover" :fields="fields" :items="getlistaMedicosCabecera"></b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters, mapMutations } from 'vuex';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'MedicoCabecera',
  components: {
    Multiselect,
  },
  data() {
    return {
      medicoSelected: null,
      especialidadSelected: null,
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '5%' } },
        {
          key: 'medico_numero', label: 'ID', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'nombre', label: 'Nombre', class: 'text-center', thStyle: { width: '30%' },
        },
        {
          key: 'especialidad', label: 'Especialidad', class: 'text-center', thStyle: { width: '30%' },
        },
        {
          key: 'estado_local', label: 'Estado', class: 'text-center', thStyle: { width: '30%' },
        },
      ],
    };
  },
  beforeDestroy() {
    this.setlistaMedicosCabecera([]);
  },
  methods: {
    actualizarInputEspecialidad() {
      if (this.medicoSelected != null) {
        this.cargarEspecialidades();
      } else {
        this.resetearEspecialidad();
      }
    },
    async cargarEspecialidades() {
      try {
        const response = await axios.get(`/clinic/patient/specialty_medic/${this.medicoSelected.id}`);
        const { especialidades } = response.data.data;
        /* eslint-disable prefer-destructuring */
        this.especialidadSelected = especialidades;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    resetearInputMedico() {
      this.medicoSelected = null;
    },
    resetearEspecialidad() {
      this.especialidadSelected = null;
    },
    agregarItem() {
      if (this.getSituaciones === null) {
        return;
      }
      if (this.medicoSelected === null) {
        util.showNotify('Elije un Medico ', 'warn');
        return;
      }
      const numero = this.getlistaMedicosCabecera.length + 1;
      const item = {
        numero,
        id: 0,
        nombre: this.medicoSelected.nombre,
        especialidad: this.especialidadSelected,
        estado_local: this.getSituaciones[1].nombre,
        medico_id: this.medicoSelected.id,
        medico_numero: this.medicoSelected.numero,
        situacion_id: this.getSituaciones[1].id,
        estado: '1',
      };
      this.addItemlistaMedicosCabecera(item);
      this.resetearInputMedico();
      this.resetearEspecialidad();
    },
    ...mapMutations('medicoCabecera', ['setlistaMedicosCabecera', 'addItemlistaMedicosCabecera']),
  },
  computed: {
    medicos() {
      return this.getMedicos.filter((medico) => (
        !this.getlistaMedicosCabecera.some((item) => item.medico_id === medico.id)
      ));
    },
    ...mapGetters('medicoCabecera', ['getMedicos', 'getlistaMedicosCabecera']),
    ...mapGetters('situaciones', ['getSituaciones']),
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
