<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-2 mb-3 border border-secondary">
        <div class="col-sm-2 pr-0">
          <div class="form-group">
            <label>Aseguradora</label>
            <multiselect
              v-model="aseguradoraSelected"
              :options="aseguradoras"
              placeholder=""
              label="nombre" track-by="nombre"
              select-label=""
              deselect-label="X"
            >
              <span slot="caret" v-if="!(aseguradoraSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>N° de Poliza:</label>
            <input
              v-model="numeroPoliza"
              :disabled="codigoAsegurado != ''"
              type="text"
              class="form-control"
              @keydown="validarCaracter"
              autocomplete="off"
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>Cod. Asegurado:</label>
            <input
              v-model="codigoAsegurado"
              :disabled="numeroPoliza != ''"
              type="text"
              class="form-control"
              @keydown="validarCaracter"
              autocomplete="off"
            >
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label>Fecha Venc.</label>
            <date-picker
              class="col-sm-10 px-0"
              v-model="fechaVencimiento"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            ></date-picker>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group" v-if="tipoSeguroisConjunto">
            <label for="comboTipo">Tipo</label>
            <multiselect
              id="comboTipo"
              v-model="tipoFamiliarSelected"
              :options="getTiposFamiliaresAuto"
              track-by="id"
              label="nombre"
              placeholder=""
              select-label=""
              deselect-label="X"
            >
              <span slot="caret" v-if="!(tipoFamiliarSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-1">
          <div class="form-group" v-if="tipoSeguroisConjunto && !existeTitularEnListaSeguros">
            <label for="comboCantidad">Cantidad</label>
            <multiselect
              id="comboCantidad"
              v-model="cantidadSelected"
              :allow-empty="false"
              :options="cantidades"
              placeholder=""
              select-label=""
              deselect-label="X"
            >
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-1 pt-3 px-2">
          <button type="submit" class="btn btn-block btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <div class="separator-breadcrumb border-top"></div>
    <b-table
      class="table table-hover"
      :fields="autoFields"
      :items="seguroMedicoActivos"
    >
    <template #cell(numero)="items">
      {{  items.index + 1 }}
    </template>
    <template #cell(eliminar)="items">
      <i class="nav-icon i-Close-Window text-danger font-weight-bold"
        title="eliminar"
        @click="eliminarItem(items.item)"
        style="cursor: pointer"
      ></i>
    </template>
    </b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import moment from 'moment';
import { mapMutations, mapGetters } from 'vuex';
import { util } from '../../../plugins/util';

export default {
  name: 'SeguroMedico',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      fechaVencimiento: new Date(),
      aseguradoraSelected: null,
      numeroPoliza: '',
      codigoAsegurado: '',
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '10%' } },
        {
          key: 'aseguradora', label: 'Aseguradora', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'numero_poliza', label: 'N° de Poliza', class: 'text-center', thStyle: { width: '22%' },
        },
        {
          key: 'codigo_aseguradora', label: 'Cod. de Asegurado', class: 'text-center', thStyle: { width: '22%' },
        },
        {
          key: 'fecha_venc', label: 'Fecha de Vencimiento', class: 'text-center', thStyle: { width: '21%' },
        },
        {
          key: 'tipo_familiar_nombre', label: 'Tipo', class: 'text-center', thStyle: { width: '21%' },
        },
        {
          key: 'cantidad', label: 'Cantidad', class: 'text-center', thStyle: { width: '21%' },
        },
        {
          key: 'eliminar', label: '', class: 'text-center', thStyle: { width: '2%' },
        },
      ],
      tipoFamiliarSelected: null,
      cantidadSelected: null,
      cantidades: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  beforeDestroy() {
    this.setListaSeguros([]);
  },
  methods: {
    validarCaracter(event) {
      const validKeys = new RegExp('^[ñÑa-zA-Z0-9-]*$');
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    resetearInputAseguradora() {
      this.aseguradoraSelected = null;
    },
    resetearInputNumeroPoliza() {
      this.numeroPoliza = '';
    },
    resetearInputCodigoAsegurado() {
      this.codigoAsegurado = '';
    },
    resetearInputFechaVencimiento() {
      this.fechaVencimiento = new Date();
    },
    resetearTipo() {
      this.tipoFamiliarSelected = null;
    },
    resetearCantidad() {
      this.cantidadSelected = null;
    },
    agregarItem() {
      if (this.aseguradoraSelected === null) {
        util.showNotify('Elige una Aseguradora', 'warn');
        return;
      }
      this.numeroPoliza = this.numeroPoliza.trim();
      if (this.numeroPoliza === '' && this.codigoAsegurado === '') {
        util.showNotify('Escribe un Numero de Poliza', 'warn');
        return;
      }
      this.codigoAsegurado = this.codigoAsegurado.trim();
      if (this.codigoAsegurado === '' && this.numeroPoliza === '') {
        util.showNotify('Escribe un Codigo de Asegurado', 'warn');
        return;
      }
      if (this.fechaVencimiento === null) {
        util.showNotify('Escribe una fecha de Vencimiento Valida', 'warn');
        return;
      }

      let tipoFamiliarId = null;
      let tipoFamiliarNombre = null;
      let cantidad = 0;
      if (this.tipoSeguroisConjunto) {
        if (this.tipoFamiliarSelected === null) {
          util.showNotify('Elige un tipo de Familiar', 'warn');
          return;
        }
        if (this.existeTitularEnListaSeguros) {
          const TITULAR = 1;
          const item = this.getlistaSeguros.find((el) => el.tipo_familiar_id === TITULAR);
          if (this.seguroMedicoActivos.length > item.cantidad) {
            util.showNotify('Ya se alcanzó el máximo de beneficiarios.', 'warn');
            return;
          }
        } else {
          if (this.cantidadSelected === null) {
            util.showNotify('Elige una cantidad', 'warn');
            return;
          }
          cantidad = this.cantidadSelected;
        }

        tipoFamiliarId = this.tipoFamiliarSelected.id;
        tipoFamiliarNombre = this.tipoFamiliarSelected.nombre;
      }

      const numero = this.getlistaSeguros.length + 1;
      const item = {
        numero,
        id: 0,
        aseguradora: this.aseguradoraSelected.nombre,
        fecha_venc: moment(this.fechaVencimiento).format('DD/MM/YYYY'),
        aseguradora_id: this.aseguradoraSelected.id,
        numero_poliza: this.numeroPoliza,
        codigo_aseguradora: this.codigoAsegurado,
        fecha_vencimiento: moment(this.fechaVencimiento).format('YYYY-MM-DD'),
        tipo_familiar_id: tipoFamiliarId,
        tipo_familiar_nombre: tipoFamiliarNombre,
        cantidad,
        estado: 1,
        key: `SEGURO_MEDICO ${new Date().getTime()}`,
      };
      this.addItemListaSeguros(item);
      this.resetearInputAseguradora();
      this.resetearInputNumeroPoliza();
      this.resetearInputCodigoAsegurado();
      this.resetearInputFechaVencimiento();
      this.resetearTipo();
      this.resetearCantidad();
    },
    ...mapMutations('seguroMedico', ['setListaSeguros', 'addItemListaSeguros', 'setStateSeguro']),
    eliminarItem(item) {
      if (item.id !== 0) {
        const INDEX = this.getlistaSeguros.findIndex((el) => el.id === item.id);
        this.getlistaSeguros[INDEX].estado = false;
        this.setStateSeguro(INDEX);
      } else {
        const INDEX = this.getlistaSeguros.findIndex((el) => el.key === item.key);
        this.getlistaSeguros.splice(INDEX, 1);
      }
    },
  },
  computed: {
    aseguradoras() {
      return this.getAseguradoras.filter((aseguradora) => (
        !this.getlistaSeguros.some((item) => item.aseguradora_id === aseguradora.id)
      ));
    },
    existeTitularEnListaSeguros() {
      return this.getlistaSeguros.some((item) => item.tipo_familiar_id === 1 /* 1 => Titular */);
    },
    autoFields() {
      if (this.tipoSeguroisConjunto) {
        return this.fields;
      }
      return this.fields.filter((obj) => (
        obj.key !== 'tipo_familiar_nombre' && obj.key !== 'cantidad'
      ));
    },
    getTiposFamiliaresAuto() {
      if (this.existeTitularEnListaSeguros) {
        return [this.getTiposFamiliares[1]];
      }
      return [this.getTiposFamiliares[0]];
    },
    ...mapGetters('mainForm', [
      'getTipoSeguroSelected',
      'tipoSeguroisConjunto',
    ]),
    ...mapGetters('aseguradoras', ['getAseguradoras']),
    ...mapGetters('tipoFamiliares', ['getTiposFamiliares']),
    ...mapGetters('seguroMedico', [
      'getlistaSeguros',
    ]),
    seguroMedicoActivos() {
      return this.getlistaSeguros.filter((el) => el.estado === 1);
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
