<template>
  <div>
    <form @submit.prevent="agregarItem">
      <div class="row py-3 mb-3 border border-secondary">
        <div class="col-sm-3">
          <div class="row">
            <div class="col-sm-4 pr-0 text-right">
              <label class="col-form-label">Aseguradora:</label>
            </div>
            <div class="col-sm-8">
              <multiselect v-model="aseguradoraSelected"
                :options="aseguradoras"
                placeholder=""
                label="nombre"
                track-by="nombre"
                select-label=""
                deselect-label="X"
                @input="actualizarSelectServicios">
                <span slot="caret" v-if="!(aseguradoraSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="row">
            <div class="col-sm-3 pr-0 text-right">
              <label class="col-form-label">Servicio:</label>
            </div>
            <div class="col-sm-9">
              <multiselect v-model="servicioSelected"
                :options="servicios"
                placeholder=""
                label="nombre"
                track-by="nombre"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(servicioSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="row">
            <div class="col-sm-5 pr-0 text-right">
              <label class="col-form-label">Cobertura:</label>
            </div>
            <div class="col-sm-7">
              <b-form-select class="pl-1"
               v-model="tipoValorSelected"
               :options="getTipoCoberturas">
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="col-sm-1 px-0">
          <input type="text"
           class="form-control"
           v-model="cobertura"
           @input="validarValor"
           @blur="convertirCoberturaMonetario">
        </div>
        <div class="col-sm-1">
          <b-form-select v-show="tipoValorSelected === 2" v-model="monedaSelected"
          :options="getMonedas">
          </b-form-select>
        </div>
        <div class="col-sm-2 text-center">
          <button type="submit" class="btn btn-warning">Agregar</button>
        </div>
      </div>
    </form>
    <b-table class="table table-hover"
     :fields="fields"
     :items="getlistaCoberturaSeguro">
    </b-table>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters, mapMutations } from 'vuex';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'CoberturaSeguro',
  components: {
    Multiselect,
  },
  async created() {
    /* eslint-disable prefer-destructuring */
    this.monedaSelected = this.getMonedas[0].value;
  },
  data() {
    return {
      aseguradoraSelected: null,
      servicioSelected: null,
      tipoValorSelected: 1,
      monedaSelected: null,
      servicios: [],
      cobertura: '',
      fields: [
        { key: 'numero', label: 'N°', thStyle: { width: '5%' } },
        {
          key: 'aseguradora_numero', label: 'ID', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'aseguradora', label: 'Aseguradora', class: 'text-center', thStyle: { width: '30%' },
        },
        {
          key: 'servicio', label: 'Servicio', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'cobertura', label: 'Cobertura', class: 'text-center', thStyle: { width: '15%' },
        },
        {
          key: 'estado_local', label: 'Estado', class: 'text-center', thStyle: { width: '20%' },
        },
      ],
    };
  },
  beforeDestroy() {
    this.setlistaCoberturaSeguro([]);
  },
  methods: {
    actualizarSelectServicios() {
      this.servicioSelected = null;
      this.servicios = [];
      if (this.aseguradoraSelected != null) {
        this.cargarServicios();
      }
    },
    async cargarServicios() {
      try {
        const response = await axios.get(`/clinic/insurer/service/${this.aseguradoraSelected.id}`);
        const { servicios } = response.data.data;
        this.servicios = servicios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    validarValor(event) {
      if (this.esMonetario()) {
        if (event.data === null) {
          return;
        }
        const validKeys = new RegExp('^[0-9.,]*$');
        if (!validKeys.test(event.data)) {
          this.cobertura = '';
        }
      } else {
        if (event.data === null) {
          return;
        }
        const validKeys = new RegExp('^[0-9,]*$');
        if (!validKeys.test(event.data)) {
          this.cobertura = '';
        }
        if (this.cobertura < 0 || this.cobertura > 100) {
          this.cobertura = '';
        }
      }
    },
    esMonetario() {
      return this.tipoValorSelected === 2;
    },
    convertirCoberturaMonetario() {
      if (this.esMonetario()) {
        if (!(this.cobertura === null || this.cobertura === '')) {
          this.cobertura = this.convertirValorMonetario(this.cobertura);
        }
      }
    },
    convertirValorMonetario(monto) {
      const valorSinSeparadorMil = monto.replace('.', '');
      const valorFormateado = valorSinSeparadorMil.replace(',', '.');
      const valorMonetario = parseFloat(valorFormateado).toLocaleString('es-Bo', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return valorMonetario;
    },
    resetearInputAseguradora() {
      this.aseguradoraSelected = null;
    },
    resetearInputServicio() {
      this.servicioSelected = null;
    },
    resetearComboTipo() {
      this.tipoValorSelected = 1;
    },
    resetearInputCobertura() {
      this.cobertura = '';
    },
    toDecimal(valor) {
      const numero = valor.replace('.', '');
      return numero.replace(',', '.');
    },
    agregarItem() {
      if (this.aseguradoraSelected === null) {
        util.showNotify('Elije una Aseguradora', 'warn');
        return;
      }
      if (this.servicioSelected === null) {
        util.showNotify('Elije un Servicio', 'warn');
        return;
      }
      this.cobertura = this.cobertura.trim();
      if (this.cobertura === '') {
        util.showNotify('Coloca un Monto Valido', 'warn');
        return;
      }
      const simbolo = this.getMonedas.filter((elemento) => (
        elemento.value === this.monedaSelected
      ))[0].simbolo;
      const unidad = (this.tipoValorSelected === 1) ? '%' : simbolo;
      const numero = this.getlistaCoberturaSeguro.length + 1;
      const monto = this.toDecimal(this.cobertura);
      const item = {
        numero,
        id: 0,
        aseguradora_id: this.aseguradoraSelected.id,
        aseguradora_numero: this.aseguradoraSelected.numero,
        aseguradora: this.aseguradoraSelected.nombre,
        servicio_id: this.servicioSelected.id,
        servicio: this.servicioSelected.nombre,
        tipo_cobertura_seguro_id: this.tipoValorSelected,
        cobertura: `${this.cobertura} ${unidad}`,
        monto,
        moneda_id: this.monedaSelected,
        estado_local: this.getSituaciones[1].nombre,
        situacion_id: this.getSituaciones[1].id,
        estado: 'true',
      };
      this.addItemlistaCoberturaSeguro(item);
      this.resetearInputAseguradora();
      this.resetearInputServicio();
      this.resetearInputServicio();
      this.resetearComboTipo();
      this.resetearInputCobertura();
    },
    ...mapMutations('coberturaSeguro', ['setlistaCoberturaSeguro', 'addItemlistaCoberturaSeguro']),
  },
  computed: {
    aseguradoras() {
      return this.getAseguradoras.filter((aseguradora) => (
        !this.getlistaCoberturaSeguro.some((item) => item.aseguradora_id === aseguradora.id)
      ));
    },
    ...mapGetters('aseguradoras', ['getAseguradoras']),
    ...mapGetters('situaciones', ['getSituaciones']),
    ...mapGetters('coberturaSeguro', ['getTipoCoberturas', 'getMonedas', 'getlistaCoberturaSeguro']),
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
